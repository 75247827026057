import styled from 'styled-components';
import IconArrowGreenRight from '../../images/icon-arrow-green-right.png';

export const Body = styled.div`
  padding: 40px 110px 0;
  @media (max-width: 991px) {
    padding: 35px;
  }
  overflow: hidden;
`;
export const ImageStyleBlockRight = styled.img`
  width: ${(props) => (props.$width ? props.$width : '522px')};
  height: auto;
  float: right;
  text-align: center;
  margin: 0 0 30px 75px;
  @media (max-width: 991px) {
    display: block;
    float: none;
    width: 100% !important;
    margin: 0 0 30px 0 !important;
    max-width: 300px;
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #55aa4c;
  transition: 0.25s ease-out;
`;
export const Header3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 1399px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const Header4 = styled.h4`
  overflow: hidden;
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1439px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const ParaText = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  :last-child {
    margin: 0px;
  }
  color: rgba(0, 0, 0, 0.8);
  i {
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 991px) {
      font-size: 16px;
    }
  }
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
  code {
    font-family: 'HelveticaNeue-Light', arial, sans-serif;
    font-size: 20px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.6);
  }
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 17px 35px 13px 35px;
    margin: 0 5px 5px 0;
    font-family: 'Adelle-Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #55aa4c;
    border: 2px solid #55aa4c;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    @media (max-width: 767px) {
      padding: 10px 20px 6px 20px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
    }
    :hover {
      top: -5px;
      color: #55aa4c;
      border-color: #55aa4c;
      box-shadow: 0 5px 0 0 #55aa4c;
    }
  }
  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const ListContainer = styled.ul`
  padding: 0;
  margin: 0 0 30px 0;
`;
export const OrderedListContainer = styled.ol`
  padding: 0;
  margin: 0 0 30px 0;
  list-style-type: none;
  counter-reset: standard-counter;
  li {
    position: relative;
    margin: 0;
    padding: 15px 0 15px 65px;
    background: none !important;
    ::before {
      content: counter(standard-counter);
      counter-increment: standard-counter;
      position: absolute;
      left: 0;
      top: 12px;
      width: 44px;
      height: 40px;
      text-align: center;
      padding: 4px 0 0 0;
      font-family: 'Adelle-Bold', arial, sans-serif;
      font-size: 30px;
      line-height: 30px;
      color: #55aa4c;
      border: 2px solid #55aa4c;
      border-radius: 50%;
      @media (max-width: 767px) {
        top: 18px;
        width: 34px;
        height: 30px;
        padding: 4px 0 0 0;
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
`;
export const ListText = styled.li`
  padding: 0 0 15px 30px;
  background: url(${IconArrowGreenRight}) no-repeat 0 9px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  ${ParaText} {
    margin: 0;
  }
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
export const HorizontalBreak = styled.hr`
  margin-top: ${(props) => props.$marginTop ?? '0px'};
  margin-right: ${(props) => props.$marginRight ?? '0px'};
  margin-bottom: ${(props) => props.$marginBottom ?? '0px'};
  margin-left: ${(props) => props.$marginLeft ?? '0px'};
`;
export const Header2 = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 40px;
  line-height: 45px;
  color: #0e4c80;
  @media (max-width: 1399px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26px;
  }
`;
export const ItalicParaText = styled(ParaText)`
  font-style: italic;
`;
export const AnchorButton = styled.a`
  padding: 16px 85px 14px 15px;
  margin: 0 5px 30px 0;
  text-decoration: none;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase !important;
  text-align: left;
  color: #037ec4;
  background: #ebf5fa url(${IconArrowGreenRight}) no-repeat 95% center;
  background-size: 12px auto;
  &:hover {
    background: #ebf5fa url(${IconArrowGreenRight}) no-repeat 91% center;
    background-size: 12px auto;
    transition: background-position 0.15s ease-out;
    border: none;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;
export const Header = styled.h1`
  font-family: 'MrAlex', sans-serif;
  font-weight: normal !important;
  color: #00539d;
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 10px;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
